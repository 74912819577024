<template>
  <div>
    <el-card style="height:880px">
      <div class="additem-title f14" v-if="order_no==''">
        <span class="mr-30" :class="{active:type==1}" @click="tab(1)">专利出售</span>
        <span :class="{active:type==2}" @click="tab(2)">商标出售</span>
      </div>
      <div v-show="type==1" class="mt-30">
        <el-form
          :rules="rules1"
          ref="form1"
          hide-required-asterisk
          :model="form1"
          label-width="82px"
          class="flex"
        >
          <div style="margin-right:84px">
            <el-form-item label="联系人" prop="contacts_name">
              <el-input v-model="form1.contacts_name" placeholder="请输入联系人称呼"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form1.phone" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="专利号" prop="patent_no">
              <el-input v-model="form1.patent_no" placeholder="请输入专利号"></el-input>
            </el-form-item>
            <el-form-item label="专利权人" prop="holder_name">
              <el-input v-model="form1.holder_name" placeholder="请输入专利权人"></el-input>
            </el-form-item>
            <el-form-item label="专利名称" prop="name">
              <el-input v-model="form1.name" placeholder="请输入专利名称"></el-input>
            </el-form-item>
            <el-form-item label="专利类型" prop="patent_type">
              <el-select v-model="form1.patent_type" placeholder="请选择专利类型">
                <el-option label="发明专利" value="发明专利"></el-option>
                <el-option label="实用新型" value="实用新型"></el-option>
                <el-option label="外观专利" value="外观专利"></el-option>
                <el-option label="国际专利" value="国际专利"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通用行业" prop="suit_industry">
              <el-select v-model="form1.suit_industry" placeholder="请选择所属行业">
                <el-option label="生活日用" value="生活日用"></el-option>
                <el-option label="机械工程" value="机械工程"></el-option>
                <el-option label="固定建筑" value="固定建筑"></el-option>
                <el-option label="物理运输" value="物理运输"></el-option>
                <el-option label="电学" value="电学"></el-option>
                <el-option label="物理" value="物理"></el-option>
                <el-option label="纺织制造" value="纺织制造"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="专利状态" prop="patent_status">
              <el-select v-model="form1.patent_status" placeholder="请选择专利状态">
                <el-option label="即将授权" value="即将授权"></el-option>
                <el-option label="授权未缴费" value="授权未缴费"></el-option>
                <el-option label="等专利回复" value="等专利回复"></el-option>
                <el-option label="已下证" value="已下证"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专利售价" prop="price">
              <el-input v-model="form1.price" placeholder="请输入专利售价"></el-input>
            </el-form-item>
            <el-form-item label="上传证书" prop="design_img">
              <el-upload
                drag
                class="upload-demo"
                action="/system/oss/uploadFile"
                :on-progress='progress'
                :on-success="success"
                :headers="myHeaders"
                :show-file-list="false" 
                accept=".jpg, .jpeg, .png"
              >
                <div class="el-upload__text" style="padding:10px;">
                  <div v-loading="loading">
                    <el-image
                     class="m-0"
                      v-if="form1.design_img"
                      style="width: 100px; height: 130px;display:block"
                      :src="form1.design_img"
                      fit="contain"
                    ></el-image>
                    <i v-else class="el-icon-upload" style="display:block"></i>
                    <em>点击上传图片</em>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <!-- <el-form-item label="专利介绍" prop="synopsis">
              <el-input
                v-model="form1.synopsis"
                type="textarea"
                placeholder="请对专利信息进行详细描述"
                :rows="7"
                style="height:160px"
              ></el-input>
            </el-form-item>-->
          </div>
        </el-form>
      </div>
      <div v-show="type==2" class="mt-30">
        <el-form
          ref="form2"
          :model="form2"
          :rules="rules2"
          label-width="110px"
          hide-required-asterisk
          class="flex"
        >
          <div style="margin-right:84px">
            <el-form-item label="联系人" prop="contacts_name">
              <el-input v-model="form2.contacts_name	" placeholder="请输入联系人称呼"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form2.phone" placeholder="请输入联系人手机号"></el-input>
            </el-form-item>
            <el-form-item label="商标注册号" prop="trademark_no">
              <el-input v-model="form2.trademark_no" placeholder="请输入商标注册号"></el-input>
            </el-form-item>
            <el-form-item label="商标名称" prop="name">
              <el-input v-model="form2.name" placeholder="请输入商标名称"></el-input>
            </el-form-item>
            <!-- <el-form-item label="商标标题" prop="title">
              <el-input v-model="form2.title" placeholder="请输入商标标题"></el-input>
            </el-form-item>-->
            <!-- <el-form-item label="商标关键词" prop="antistop">
              <el-input v-model="form2.antistop" placeholder="请输入商标关键词"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="describe">
              <el-input v-model="form2.describe"></el-input>
            </el-form-item>-->
            <el-form-item label="商标分类" prop="trademark_cate">
              <el-input v-model="form2.trademark_cate" placeholder="请输入商标分类"></el-input>
            </el-form-item>
            <el-form-item label="使用范围" prop="trademark_range">
              <el-input v-model="form2.trademark_range" placeholder="请输入商标使用范围"></el-input>
            </el-form-item>
            <el-form-item label="商标售价" prop="price">
              <el-input v-model="form2.price" placeholder="请输入商标售价"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="商标有效期" prop="time">
              <el-date-picker
                style="width:320px"
                v-model="form2.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-M-d"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="商标结构" prop="trademark_structure">
              <el-select v-model="form2.trademark_structure" placeholder="选择商标结构">
                <el-option label="中文" value="中文"></el-option>
                <el-option label="中文+英文" value="中文+英文"></el-option>
                <el-option label="图形" value="图形"></el-option>
                <el-option label="中文+图形" value="中文+图形"></el-option>
                <el-option label="英文" value="英文"></el-option>
                <el-option label="英文+图形" value="英文+图形"></el-option>
                <el-option label="中文+英文+图形" value="中文+英文+图形"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上传证书" prop="design_img">
              <el-upload
                class="upload-demo"
                drag
                action="/system/oss/uploadFile"
                :on-success="success2"
                :on-progress='progress'
                :headers="myHeaders"
                :show-file-list="false"
                accept=".jpg, .jpeg, .png"
              >
              <div class="el-upload__text" style="padding:10px;">
                  <div v-loading="loading">
                    <el-image
                     class="m-0"
                      v-if="form2.design_img"
                      style="width: 100px; height: 130px;display:block"
                      :src="form2.design_img"
                      fit="contain"
                    ></el-image>
                    <i v-else class="el-icon-upload" style="display:block"></i>
                    <em>点击上传图片</em>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <!-- <el-form-item label="商标介绍" prop="synopsis">
              <el-input
                v-model="form2.synopsis"
                type="textarea"
                placeholder="请对商标信息进行详细描述"
                :rows="7"
                style="height:160px"
              ></el-input>
            </el-form-item>-->
          </div>
        </el-form>
      </div>
      <div class="flex" style="padding-left:108px">
        <div class="submit mt-30" @click="$router.go(-1)">返回</div>
        <div class="submit mt-30 ml-30" @click="submit">立即提交</div>
      </div>
    </el-card>
  </div>
</template>
<script>
var token = localStorage.getItem("token");
export default {
  created() {
    this.type = this.$route.query.type || 1;
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getinfo();
    }
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(value)) {
          callback(new Error("手机格式不正确"));
        } else {
          callback();
        }
      }
    };
    var validatePrice = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入出售价格"));
      } else {
        var priceReg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if (!priceReg.test(value)) {
          callback(new Error("请输入正确的产品价格:整数或者保留两位小数"));
        } else {
          callback();
        }
      }
    };
    return {
      loading:false,
      myHeaders: { Authorization: token },
      form1: {
        contacts_name: "",
        phone: "",
        patent_no: "",
        holder_name: "",
        name: "",
        patent_type: "",
        suit_industry: "",
        patent_status: "",
        price: "",
        design_img: "",
        synopsis: ""
      },
      rules1: {
        contacts_name: [
          { required: true, message: "请输入联系人称呼", trigger: "blur" }
        ],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        patent_no: [
          { required: true, message: "请输入专利号", trigger: "blur" }
        ],
        holder_name: [
          { required: true, message: "请输入专利权人", trigger: "blur" }
        ],
        name: [{ required: true, message: "请输入专利名称", trigger: "blur" }],
        patent_type: [
          { required: true, message: "请选择专利类型", trigger: "change" }
        ],
        suit_industry: [
          { required: true, message: "请选择所属行业", trigger: "change" }
        ],
        patent_status: [
          { required: true, message: "请选择专利状态", trigger: "change" }
        ],
        price: [{ validator: validatePrice, trigger: "blur" }],
        design_img: [{ required: true, message: "请上传证书", trigger: "blur" }]
        // synopsis: [
        //   {
        //     required: true,
        //     message: "请对专利信息进行详细描述",
        //     trigger: "blur"
        //   }
        // ]
      },
      form2: {
        contacts_name: "",
        phone: "",
        trademark_no: "",
        name: "",
        title: "",
        antistop: "",
        describe: "",
        trademark_cate: "",
        trademark_range: "",
        price: "",
        time: "",
        trademark_structure: "",
        design_img: "",
        synopsis: ""
      },
      rules2: {
        contacts_name: [
          { required: true, message: "请输入联系人称呼", trigger: "blur" }
        ],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        trademark_no: [
          { required: true, message: "请输入商标注册号", trigger: "blur" }
        ],
        name: [{ required: true, message: "请输入商标名称", trigger: "blur" }],
        title: [{ required: true, message: "请输入商标标题", trigger: "blur" }],
        antistop: [
          { required: true, message: "请输入商标关键词", trigger: "blur" }
        ],
        describe: [{ required: true, message: "请描述", trigger: "blur" }],
        trademark_cate: [
          { required: true, message: "请输入商标分类", trigger: "blur" }
        ],
        trademark_range: [
          { required: true, message: "请输入商标使用范围", trigger: "blur" }
        ],
        price: [{ validator: validatePrice, trigger: "blur" }],
        time: [
          { required: true, message: "请选择商标有效期", trigger: "change" }
        ],
        trademark_structure: [
          { required: true, message: "选择商标结构", trigger: "change" }
        ],
        design_img: [
          { required: true, message: "请上传证书", trigger: "blur" }
        ],
        synopsis: [
          {
            required: true,
            message: "请对商标信息进行详细描述",
            trigger: "blur"
          }
        ]
      },
      order_no: "",
      id: "",
      type: "1",
      limit: 1
    };
  },
  methods: {
    tab(type) {
      this.type = type;
    },
    submit() {
      if (this.type == 1) {
        let url = "/client/transaction/submitPatent";
        this.$refs.form1.validate(res => {
          if (res) {
            this.addEdit(url, this.form1, this.order_no, this.type);
          }
        });
      }
      if (this.type == 2) {
        let url = "/client/transaction/submitTrademark";
        this.$refs.form2.validate(res => {
          if (res) {
            this.form2.trademark_start_time = this.form2.time[0];
            this.form2.trademark_end_time = this.form2.time[1];
            this.addEdit(url, this.form2, this.order_no, this.type);
          }
        });
      }
    },
    success(res) {
      this.loading=false
      if (res.code != 200) {
        this.$message(res.msg);
      }
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.form1.design_img = res.data.url;
      }
    },
    progress(){
      this.loading=true
    },
    success2(res) {
      this.loading=false
      if (res.code != 200) {
        this.fileList2 = [];
        this.$message(res.msg);
      }
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.form2.design_img = res.data.url;
      }
    },

    addEdit(url, form, order_no, type) {
      this.$request.post(url, { ...form, order_no, type }).then(res => {});
    },
    getinfo() {
      let url =
        this.type == 1
          ? `/client/transaction/editPatentShow?id=${this.id}`
          : `/client/transaction/editTrademarkShow?id=${this.id}`;
      this.$request.get(url).then(res => {
        if (res.data.code == 200) {
          if (this.type == 1) {
            res.data.data.design_img=res.data.data.design_img[0]
            this.form1 = res.data.data;
          }
          if (this.type == 2) {
            res.data.data.design_img=res.data.data.design_img[0]
            this.form2 = res.data.data;
            this.form2.time = [];
            this.form2.time.push(
              res.data.data.trademark_start_time,
              res.data.data.trademark_end_time
            );
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.additem-title {
  border-bottom: 1px solid #e6e6e6;
}
.additem-title span {
  font-weight: 700;
  display: inline-block;
  padding: 10px;
  color: #bbb;
  cursor: pointer;
}
.additem-title span.active {
  border-bottom: 2px solid #2962ff;
  color: #000;
}
.el-input {
  height: 32px;
  width: 320px;
}
.el-card >>> .el-textarea__inner {
  border-color: #bbb;
}
.el-card >>> .el-textarea__inner:focus {
  border-color: #2962ff;
}
.el-card >>> .el-input__inner {
  height: 32px;
  border-color: #bbb;
}
.el-card >>> .el-input__inner:focus {
  border-color: #2962ff;
}
.el-select {
  width: 320px;
}
.el-form-item .el-form-item__label {
  color: #000 !important;
}
.el-card >>> .el-upload-dragger {
  width: 320px;
  border-color: #aaa;
}
.submit {
  width: 124px;
  height: 48px;
  opacity: 1;
  background: #2962ff;
  border-radius: 8px;
  line-height: 48px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
</style>
